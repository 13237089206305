/*
window.require = require
window.jQuery = window.$ = require('jquery')


var tri = require('tripartite')
var tripartite = tri

const SwipeListener = require('swipe-listener')
const Shiner = require('shiner/shiner-no-jquery')($)
window.Shiner = Shiner
let createShows = require('shiner/create-shows')

$(function() {
	// createShows(Shiner, SwipeListener ).forEach(shine => shine.shineOn())
	// require('./enable-remote-logging')

	$('header .tribar').on('click', function(evt) {
		evt.preventDefault()
		$('header nav').toggleClass('open')
		$('body').toggleClass('locked');
	})
	$('header .menu li').removeClass('current')
	$('header .menu a').each(function() {
		if($(this).attr('href') == window.location.pathname) {
			$(this).closest('li').addClass('current')
		}
	})
})
*/
const sampleVideoColor = videoElement => {
	const canvas = document.createElement('CANVAS')
	const ctx = canvas.getContext('2d')
	const { videoWidth, videoHeight } = videoElement
	canvas.width = videoWidth
	canvas.height = videoHeight

	ctx.drawImage(videoElement, 0, 0, videoWidth, videoHeight)
	let imgData = ctx.getImageData(1, 1, 1, 1).data ///top left corner
	return imgData
	const [r, g, b] = imgData

	return `rgb(${r},${g},${b})`
}
function setBack(value) {
	document.querySelector('.page-std').style.background = value
	document.querySelector('.inner-header').style.background = value
}
window.sampleVideoColor = sampleVideoColor

// let killTimer
let swarmVideo = document.querySelector('video.swarm')
if (swarmVideo) {
	Object.defineProperty(HTMLMediaElement.prototype, 'playing', {
		get: function () {
			return !!(this.currentTime > 0 && !this.paused && !this.ended && this.readyState > 2)
		}
	})
	function playVid() {
		console.log('playvid')
		if(!swarmVideo.playing) {
			swarmVideo.play()
		}
	}

	swarmVideo.addEventListener('play', (evt) => {
		// console.timeEnd('vid')
		let vidColor = sampleVideoColor(swarmVideo)
		// console.log(vidColor)
		const [r, g, b] = vidColor
		if(r == 0 || g == 0 || b == 0) {

		}
		else {
			setBack(`rgb(${r},${g},${b})`)
		}
		// if(killTimer) {
		// 	clearTimeout(killTimer)
		// }
	})
	// swarmVideo.addEventListener('suspend', (evt) => {
	// 	console.time('vid')
	// 	killTimer = setTimeout(function() {
	// 		swarmVideo.remove()
	// 		console.log('vid removed')
	// 	}, 1000)
	// })
	document.querySelector('body').addEventListener('touchstart', playVid)
	document.querySelector('body').addEventListener('scroll', playVid)
	document.querySelector('body').addEventListener('mousedown', playVid)
	document.querySelector('html').addEventListener('scroll', playVid)
	window.addEventListener('scroll', playVid)

}